import * as yup from 'yup'

import { currencyModelSchema } from '../currency'

import { RFQStatusesSchema } from './rfq-statuses.schema'

export const vendorsRFQGeneralInfoSchema = yup
  .object({
    eta: yup
      .object({
        from: yup.string().defined(),
        to: yup.string().defined(),
      })
      .defined()
      .nullable(),
    etaAfterClarification: yup
      .object({
        from: yup.string().defined(),
        to: yup.string().defined(),
      })
      .defined()
      .nullable(),
    filesInfo: yup
      .object({
        bankDetailsPath: yup.string().defined().nullable(),
        isBankDetailsUploaded: yup.boolean().defined(),
        isPiUploaded: yup.boolean().defined(),
        piPath: yup.string().defined().nullable(),
      })
      .defined()
      .nullable(),
    formId: yup.string().defined(),
    isWinner: yup.boolean().defined(),
    linkToVrf: yup.string().defined(),
    prices: yup
      .object({
        currency: currencyModelSchema.omit(['code']).defined().nullable(),
        tax: yup.number().defined().nullable(),
        taxInUsd: yup.number().defined().nullable(),
        totalAfterTax: yup.number().defined().nullable(),
        totalAfterTaxInUsd: yup.number().defined().nullable(),
        totalBeforeTax: yup.number().defined().nullable(),
        totalBeforeTaxInUsd: yup.number().defined().nullable(),
      })
      .defined(),
    vendorCompanyName: yup.string().defined(),
    vendorId: yup.string().defined(),
    vendorName: yup.string().defined(),
    vrfStatus: RFQStatusesSchema.defined(),
    vendorResponseDate: yup.string().defined().nullable(),
  })
  .noUnknown()

export type VendorsRFQGeneralInfoModel = yup.InferType<typeof vendorsRFQGeneralInfoSchema>
