import { array, InferType, number, object, string } from 'yup'
import * as yup from 'yup'

import { currencyModelSchema } from '../currency'
import { attachmentSchema } from '../files'

import { orderDiscountModelSchema } from './order-discount.schemas'
import { orderProductModelSchema, orderProductsModelSchema } from './order-product.schemas'

const possiblePurchaseCommercialOfferStatus = ['DRAFT', 'CONFIRMED'] as const
export type PurchaseCommercialOfferStatus = (typeof possiblePurchaseCommercialOfferStatus)[number]

export const purchaseOrderCommercialOfferModelSchema = object().shape({
  status: yup
    .mixed<PurchaseCommercialOfferStatus>()
    .oneOf(possiblePurchaseCommercialOfferStatus)
    .defined(),
  id: string().defined(),
  orderId: string().defined(),
  etaMinDays: number().defined(),
  etaMaxDays: number().defined(),
  tax: number().defined(),
  currency: currencyModelSchema.optional(),
  shippingPrice: number().defined(),
  shipmentCost: number().defined(),
  vendorId: string().defined(),
  validUntil: string().defined(),
  totalPrice: number().optional(),
  subtotalPrice: number().optional(),
  priceWithoutTaxes: number().defined(),
  pickupDateFrom: string().nullable(),
  pickupDateTo: string().nullable(),
  vendor: yup
    .object({
      companyName: yup.string().defined(),
      vendorDepId: yup.string().nullable().defined(),
    })
    .defined()
    .nullable(),
  discount: orderDiscountModelSchema.defined().nullable(),
})
export const orderCommercialOffersModelSchema = array().of(purchaseOrderCommercialOfferModelSchema)
export type OrderCommercialOfferModel = InferType<typeof purchaseOrderCommercialOfferModelSchema>
export type OrderCommercialOffersModel = InferType<typeof orderCommercialOffersModelSchema>

export const purchaseOrderCommercialOfferForMutationModelSchema =
  purchaseOrderCommercialOfferModelSchema.shape({
    orderProducts: yup.array().of(orderProductModelSchema).defined(),
  })
export type PurchaseOrderCommercialOfferForMutationModel = InferType<
  typeof purchaseOrderCommercialOfferForMutationModelSchema
>

const possibleOrderCommercialOfferPackageStatusType = [
  'PREPARING',
  'IN_TRANSIT',
  'DELIVERED',
] as const

type OrderCommercialOfferPackageStatusType =
  (typeof possibleOrderCommercialOfferPackageStatusType)[number]

export const possibleOrderCommercialOfferPackageStatusTypeSchema = yup
  .mixed<OrderCommercialOfferPackageStatusType>()
  .oneOf(possibleOrderCommercialOfferPackageStatusType)

const vendorModelSchema = yup.object({
  companyName: yup.string().defined(),
  vendorDepId: yup.string().defined().nullable(),
})

export const orderCommercialOfferPackageModelSchema = yup.object({
  actualDeliveryDate: yup.string().defined().nullable(),
  commercialOfferId: yup.string().defined(),
  createdAt: yup.string().defined(),
  deletedAt: yup.string().defined().nullable(),
  etaStartDate: yup.string().defined().nullable(),
  etaEndDate: yup.string().defined().nullable(),
  trackingNumber: yup.string().defined().nullable(),
  trackingLink: yup.string().defined().nullable(),
  id: yup.string().defined(),
  orderProducts: orderProductsModelSchema.defined().default([]),
  proofOfPackageDeliveryAttachments: yup.array().of(attachmentSchema).defined().default([]),
  status: possibleOrderCommercialOfferPackageStatusTypeSchema.defined(),
  updatedAt: yup.string().defined().nullable(),
  vendorId: yup.string().defined(),
  vendor: vendorModelSchema.defined().nullable(),
})

export type OrderCommercialOffersPackageModel = yup.InferType<
  typeof orderCommercialOfferPackageModelSchema
>
